function init() {

    netNav.init({
        container : $('nav#header'),
        trigger : $('.navTrigger'),
        breakpoint : 1024
    });

}

window.addEventListener("load", function () {
    init();
});
