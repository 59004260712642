window.addEventListener("load", function () {
    if ( document.querySelectorAll('.accordeon').length ) {

        const accordeonHeader = document.querySelectorAll('.accordeon dt');

        if( accordeonHeader.length ) {

            gsap.set( '.accordeon dd' , { height: 0 } );

            accordeonHeader.forEach(function(button) {
                button.addEventListener('click', function(event) {
                    const dt = event.target.closest('dt');
                    const dd = dt.nextElementSibling;

                    if (!dt.classList.contains('js-expanded')) {
                        dt.classList.add('js-expanded');
                        gsap.set( dd,  { height: "auto" } );
                        gsap.from( dd, .2,  { height: 0, ease: Sine.easeInOut} );
                    } else {
                        dt.classList.remove('js-expanded');
                        gsap.to( dd, .2,  { height: 0, ease: Sine.easeInOut} );
                    }
                });
            });
        }
    }
});
